import { useAuth0 } from "@auth0/auth0-react";


function SubscribeButtonTra(props) {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user
  } = useAuth0();
  if (!isLoading && isAuthenticated && user && user.email) {
    return (
      <div style={{ margin: "auto" }}>
        {props.activePlanLevel !== undefined &&  
        <button
          className="button is-rounded is-size-6-mobile is-size-6-tablet"
          style={{ backgroundColor: "#32CD32", margin: "auto", color: "#ffffff" }}
          onClick={() => {
            if (props.activePlanLevel === 0) {
              // Trial
              if (props.monthly) {
                window.location.href = props.plan_obj.plan_id_monthly + `?prefilled_email=${user.email.replace("@", "%40")}`
              } else {
                window.location.href = props.plan_obj.plan_id_annually + `?prefilled_email=${user.email.replace("@", "%40")}`
              }
            } else {
              // No Trial
              if (props.monthly) {
                window.location.href = props.plan_obj.plan_id_monthly_no_trial + `?prefilled_email=${user.email.replace("@", "%40")}`
              } else {
                window.location.href = props.plan_obj.plan_id_annually_no_trial + `?prefilled_email=${user.email.replace("@", "%40")}`
              }
            }
          }}
        >
          Start {props.activePlanLevel === 0 ? "Free Trial!" : ""}
        </button>}
      </div>
    );
  } else {
    return (
      <button
        className="button is-rounded is-size-6-mobile is-size-6-tablet"
        style={{ backgroundColor: "#32CD32", margin: "auto", color: "#ffffff" }}
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: "signup",
            },
            appState: { returnTo: "/pricing?s=1" },
          })
        }
      >
        Sign up for Free!
      </button>
    );
  }
}

export default SubscribeButtonTra;
