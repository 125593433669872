import React from 'react';
import './StepIndicator.css';

const StepIndicator = (props) => {
  const steps = ["Find Products", "Scan Products", "Process Data"];

  return (
    <div className="step-indicator">
      <div className="steps-container">
        {steps.map((step, index) => (
          <React.Fragment key={`${index}-step`}>
            <div style={{ fontSize: 11, color: '#8c3e7c', width: 'min-content', }}>
              {step}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="steps-container">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <button className={`button step ${props.currentStep > index ? 'active' : ''} ${props.currentStep === index + 1 && !props.stop && props.loading ? 'is-loading' : ''}`}>
              {index + 1}
            </button>
            {index !== steps.length - 1 && (
              <div className={`line ${props.currentStep > index + 1 ? 'completed' : ''}`}></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default StepIndicator;